export const backgroundMixin = {
  computed: {
    background() {
      if (this.meta && this.meta.background) {
        return `${this.meta.background}-background`
      } else {
        return 'default-background'
      }
    }
  }
}
